*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(51 140 245 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(51 140 245 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
/* Typography */
.h1 {
  font-size: 2.625rem;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: -0.02em;
}
.h2 {
  font-size: 2rem;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: -0.02em;
}
.h3 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
}
.h4 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.375;
  letter-spacing: -0.01em;
}
@media (min-width: 768px) {
  .h1 {
    font-size: 3.25rem;
  }

  .h2 {
    font-size: 2.625rem;
  }
}
/* Buttons */
.btn,
.btn-sm {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: transparent;
  font-weight: 500;
  line-height: 1.375;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.btn {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.04), 0 4px 6px -2px rgba(0, 0, 0, 0.02);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.btn-sm {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
/* Forms */
.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(223 223 223 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
  --tw-border-opacity: 1;
  border-color: rgb(127 127 127 / var(--tw-border-opacity));
}
.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  border-radius: 0.25rem;
}
.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.form-input::placeholder,
.form-textarea::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(127 127 127 / var(--tw-placeholder-opacity));
}
.form-select {
  padding-right: 2.5rem;
}
.form-checkbox,
.form-radio {
  border-radius: 0.125rem;
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
}
/* Range slider */
:root {
  --range-thumb-size: 36px;
}
input[type="range"] {
  appearance: none;
  background: #ccc;
  border-radius: 3px;
  height: 6px;
  margin-top: (--range-thumb-size - 6px) * 0.5;
  margin-bottom: (--range-thumb-size - 6px) * 0.5;
  --thumb-size: #{--range-thumb-size};
}
input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: --range-thumb-size;
  width: --range-thumb-size;
}
input[type="range"]::-moz-range-thumb {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: --range-thumb-size;
  width: --range-thumb-size;
}
input[type="range"]::-ms-thumb {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: --range-thumb-size;
  width: --range-thumb-size;
}
input[type="range"]::-moz-focus-outer {
  border: 0;
}
/* Switch element */
.form-switch {
  position: relative;
  -webkit-user-select: none;
          user-select: none;
  width: 68px;
}
.form-switch label {
  display: block;
  cursor: pointer;
  overflow: hidden;
  border-radius: 0.25rem;
  height: 38px;
}
.form-switch label > span:first-child {
  position: absolute;
  display: block;
  border-radius: 0.25rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 30px;
  height: 30px;
  top: 4px;
  left: 4px;
  right: 50%;
  transition: all 0.15s ease-out;
}
.form-switch input[type="checkbox"]:checked + label {
  --tw-bg-opacity: 1;
  background-color: rgb(0 112 244 / var(--tw-bg-opacity));
}
.form-switch input[type="checkbox"]:checked + label > span:first-child {
  left: 34px;
}
html {
  scroll-behavior: smooth;
}
.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
/* Hamburger button */
.hamburger svg > *:nth-child(1),
.hamburger svg > *:nth-child(2),
.hamburger svg > *:nth-child(3) {
  transform-origin: center;
  transform: rotate(0deg);
}
.hamburger svg > *:nth-child(1) {
  transition:
    y 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    opacity 0.1s ease-in;
}
.hamburger svg > *:nth-child(2) {
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger svg > *:nth-child(3) {
  transition:
    y 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    width 0.1s 0.25s ease-in;
}
.hamburger.active svg > *:nth-child(1) {
  opacity: 0;
  y: 11;
  transform: rotate(225deg);
  transition:
    y 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 0.1s 0.12s ease-out;
}
.hamburger.active svg > *:nth-child(2) {
  transform: rotate(225deg);
  transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger.active svg > *:nth-child(3) {
  y: 11;
  transform: rotate(135deg);
  transition:
    y 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    width 0.1s ease-out;
}
/* Pulsing animation */
@keyframes pulseLoop {
  0% {
    opacity: 0.15;
    transform: scale(1) translateZ(0);
  }
  30% {
    opacity: 0.15;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0;
    transform: scale(1.8) translateZ(0);
  }
}
@keyframes pulseMiniLoop {
  0% {
    opacity: 0;
    transform: scale(1) translateZ(0);
  }
  30% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.3;
  }
  80% {
    opacity: 0;
    transform: scale(3) translateZ(0);
  }
}
.pulse {
  transform: scale(1);
  opacity: 0;
  transform-origin: center;
  animation: pulseLoop 10000ms linear infinite;
}
.pulse-mini {
  animation: pulseMiniLoop 6000ms linear infinite;
}
.pulse-1 {
  animation-delay: -3000ms;
}
.pulse-2 {
  animation-delay: -6000ms;
}
/* Animations delay */
.animation-delay-500 {
  animation-delay: 500ms !important;
}
.animation-delay-1000 {
  animation-delay: 1000ms !important;
}
.translate-z-0 {
  transform: translateZ(0);
}
/* Custom AOS animations */
[data-aos="zoom-y-out"] {
  transform: scaleX(1.03);
  opacity: 0;
  transition-property: transform, opacity;
}
@media screen {
  html:not(.no-js) body [data-aos="fade-up"] {
    transform: translate3d(0, 10px, 0);
  }

  html:not(.no-js) body [data-aos="fade-down"] {
    transform: translate3d(0, -10px, 0);
  }

  html:not(.no-js) body [data-aos="fade-right"] {
    transform: translate3d(-10px, 0, 0);
  }

  html:not(.no-js) body [data-aos="fade-left"] {
    transform: translate3d(10px, 0, 0);
  }

  html:not(.no-js) body [data-aos="fade-up-right"] {
    transform: translate3d(-10px, 10px, 0);
  }

  html:not(.no-js) body [data-aos="fade-up-left"] {
    transform: translate3d(10px, 10px, 0);
  }

  html:not(.no-js) body [data-aos="fade-down-right"] {
    transform: translate3d(-10px, -10px, 0);
  }

  html:not(.no-js) body [data-aos="fade-down-left"] {
    transform: translate3d(10px, -10px, 0);
  }

  html:not(.no-js) body [data-aos="zoom-in-up"] {
    transform: translate3d(0, 10px, 0) scale(0.6);
  }

  html:not(.no-js) body [data-aos="zoom-in-down"] {
    transform: translate3d(0, -10px, 0) scale(0.6);
  }

  html:not(.no-js) body [data-aos="zoom-in-right"] {
    transform: translate3d(-10px, 0, 0) scale(0.6);
  }

  html:not(.no-js) body [data-aos="zoom-in-left"] {
    transform: translate3d(10px, 0, 0) scale(0.6);
  }

  html:not(.no-js) body [data-aos="zoom-out-up"] {
    transform: translate3d(0, 10px, 0) scale(1.2);
  }

  html:not(.no-js) body [data-aos="zoom-out-down"] {
    transform: translate3d(0, -10px, 0) scale(1.2);
  }

  html:not(.no-js) body [data-aos="zoom-out-right"] {
    transform: translate3d(-10px, 0, 0) scale(1.2);
  }

  html:not(.no-js) body [data-aos="zoom-out-left"] {
    transform: translate3d(10px, 0, 0) scale(1.2);
  }
}
/* 
 * A manually imported preflight.css from https://unpkg.com/tailwindcss@3.3.3/src/css/preflight.css
 * So we can change any options that interact with antd poorly
 */
/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/
*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #EAEAEA; /* 2 */
}
::before,
::after {
  --tw-content: "";
}
/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
5. Use the user's configured `sans` font-feature-settings by default.
6. Use the user's configured `sans` font-variation-settings by default.
*/
html {
  line-height: 1.5; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */ /* 3 */
  tab-size: 4; /* 3 */
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 4 */
  font-feature-settings: normal; /* 5 */
  font-variation-settings: normal; /* 6 */
}
/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/
body {
  margin: 0; /* 1 */
  line-height: inherit; /* 2 */
}
/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/
hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
  border-top-width: 1px; /* 3 */
}
/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/
abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}
/*
Remove the default font size and weight for headings.
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
/*
Reset links to optimize for opt-in styling instead of opt-out.
*/
a {
  color: inherit;
  text-decoration: inherit;
}
/*
Add the correct font weight in Edge and Safari.
*/
b,
strong {
  font-weight: bolder;
}
/*
1. Use the user's configured `mono` font family by default.
2. Correct the odd `em` font sizing in all browsers.
*/
code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/*
Add the correct font size in all browsers.
*/
small {
  font-size: 80%;
}
/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/
table {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
  border-collapse: collapse; /* 3 */
}
/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-feature-settings: inherit; /* 1 */
  font-variation-settings: inherit; /* 1 */
  font-size: 100%; /* 1 */
  font-weight: inherit; /* 1 */
  line-height: inherit; /* 1 */
  color: inherit; /* 1 */
  margin: 0; /* 2 */
  padding: 0; /* 3 */
}
/*
Remove the inheritance of text transform in Edge and Firefox.
*/
button,
select {
  text-transform: none;
}
/*
Use the modern Firefox focus style for all focusable elements.
*/
:-moz-focusring {
  outline: auto;
}
/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/
:-moz-ui-invalid {
  box-shadow: none;
}
/*
Add the correct vertical alignment in Chrome and Firefox.
*/
progress {
  vertical-align: baseline;
}
/*
Correct the cursor style of increment and decrement buttons in Safari.
*/
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/*
Remove the inner padding in Chrome and Safari on macOS.
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/*
Add the correct display in Chrome and Safari.
*/
summary {
  display: list-item;
}
/*
Removes the default spacing and border for appropriate elements.
*/
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}
fieldset {
  margin: 0;
  padding: 0;
}
legend {
  padding: 0;
}
ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
/*
Reset default styling for dialogs.
*/
dialog {
  padding: 0;
}
/*
Prevent resizing textareas horizontally by default.
*/
textarea {
  resize: vertical;
}
/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/
input::placeholder,
textarea::placeholder {
  opacity: 1; /* 1 */
  color: #999999; /* 2 */
}
/*
Set the default cursor for buttons.
*/
button,
[role="button"] {
  cursor: pointer;
}
/*
Make sure disabled buttons don't get the pointer cursor.
*/
:disabled {
  cursor: default;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.pointer-events-none {
  pointer-events: none;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: sticky;
}
.inset-0 {
  inset: 0px;
}
.bottom-0 {
  bottom: 0px;
}
.bottom-1 {
  bottom: 0.25rem;
}
.left-0 {
  left: 0px;
}
.left-1\/2 {
  left: 50%;
}
.right-0 {
  right: 0px;
}
.right-1 {
  right: 0.25rem;
}
.top-1\/2 {
  top: 50%;
}
.top-full {
  top: 100%;
}
.-z-1 {
  z-index: -1;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-\[99999\] {
  z-index: 99999;
}
.order-first {
  order: -9999;
}
.m-auto {
  margin: auto;
}
.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.-mr-1 {
  margin-right: -0.25rem;
}
.-mt-7 {
  margin-top: -1.75rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-16 {
  margin-bottom: 4rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-auto {
  margin-left: auto;
}
.mr-4 {
  margin-right: 1rem;
}
.mt-0 {
  margin-top: 0px;
}
.mt-2 {
  margin-top: 0.5rem;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.\!table {
  display: table !important;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.hidden {
  display: none;
}
.aspect-video {
  aspect-ratio: 16 / 9;
}
.h-1\/3 {
  height: 33.333333%;
}
.h-10 {
  height: 2.5rem;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-3 {
  height: 0.75rem;
}
.h-40 {
  height: 10rem;
}
.h-6 {
  height: 1.5rem;
}
.h-8 {
  height: 2rem;
}
.h-\[350px\] {
  height: 350px;
}
.h-\[410px\] {
  height: 410px;
}
.h-\[445px\] {
  height: 445px;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.min-h-screen {
  min-height: 100vh;
}
.w-1\/12 {
  width: 8.333333%;
}
.w-1\/2 {
  width: 50%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-1\/4 {
  width: 25%;
}
.w-1\/5 {
  width: 20%;
}
.w-10 {
  width: 2.5rem;
}
.w-10\/12 {
  width: 83.333333%;
}
.w-2\/3 {
  width: 66.666667%;
}
.w-3 {
  width: 0.75rem;
}
.w-3\/12 {
  width: 25%;
}
.w-3\/4 {
  width: 75%;
}
.w-3\/5 {
  width: 60%;
}
.w-32 {
  width: 8rem;
}
.w-4\/5 {
  width: 80%;
}
.w-40 {
  width: 10rem;
}
.w-5\/6 {
  width: 83.333333%;
}
.w-6 {
  width: 1.5rem;
}
.w-8 {
  width: 2rem;
}
.w-9\/12 {
  width: 75%;
}
.w-auto {
  width: auto;
}
.w-full {
  width: 100%;
}
.w-px {
  width: 1px;
}
.min-w-0 {
  min-width: 0px;
}
.min-w-\[600px\] {
  min-width: 600px;
}
.max-w-3xl {
  max-width: 48rem;
}
.max-w-6xl {
  max-width: 72rem;
}
.max-w-sm {
  max-width: 24rem;
}
.max-w-xl {
  max-width: 36rem;
}
.max-w-xs {
  max-width: 20rem;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.shrink-0 {
  flex-shrink: 0;
}
.flex-grow {
  flex-grow: 1;
}
.grow {
  flex-grow: 1;
}
.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-16 {
  --tw-translate-y: -4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-2 {
  --tw-translate-y: -0.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-16 {
  --tw-translate-y: 4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\!transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.select-none {
  -webkit-user-select: none;
          user-select: none;
}
.list-disc {
  list-style-type: disc;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.content-center {
  align-content: center;
}
.content-start {
  align-content: flex-start;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-items-stretch {
  justify-items: stretch;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-8 {
  gap: 2rem;
}
.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.self-center {
  align-self: center;
}
.justify-self-start {
  justify-self: start;
}
.justify-self-end {
  justify-self: end;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.border {
  border-width: 1px;
}
.border-4 {
  border-width: 4px;
}
.border-8 {
  border-width: 8px;
}
.border-t {
  border-top-width: 1px;
}
.border-solid {
  border-style: solid;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(234 234 234 / var(--tw-border-opacity));
}
.border-transparent {
  border-color: transparent;
}
.border-zinc-200 {
  --tw-border-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 112 244 / var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 251 251 / var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 234 234 / var(--tw-bg-opacity));
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(25 25 25 / var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}
.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.from-blue-500 {
  --tw-gradient-from: #338CF5 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(51 140 245 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.to-teal-400 {
  --tw-gradient-to: #4FD1C5 var(--tw-gradient-to-position);
}
.bg-clip-text {
  -webkit-background-clip: text;
          background-clip: text;
}
.fill-current {
  fill: currentColor;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-px {
  padding: 1px;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-12 {
  padding-bottom: 3rem;
}
.pb-16 {
  padding-bottom: 4rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-20 {
  padding-bottom: 5rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-48 {
  padding-bottom: 12rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pt-0 {
  padding-top: 0px;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pt-12 {
  padding-top: 3rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-24 {
  padding-top: 6rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pt-3\.5 {
  padding-top: 0.875rem;
}
.pt-32 {
  padding-top: 8rem;
}
.pt-36 {
  padding-top: 9rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-8 {
  padding-top: 2rem;
}
.text-center {
  text-align: center;
}
.align-middle {
  vertical-align: middle;
}
.text-2xl {
  font-size: 1.5rem;
}
.text-3xl {
  font-size: 2rem;
}
.text-5xl {
  font-size: 3.25rem;
}
.text-\[10px\] {
  font-size: 10px;
}
.text-\[14px\] {
  font-size: 14px;
}
.text-\[16px\] {
  font-size: 16px;
}
.text-base {
  font-size: 1rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-sm {
  font-size: 0.875rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
}
.font-bold {
  font-weight: 700;
}
.font-extrabold {
  font-weight: 800;
}
.font-medium {
  font-weight: 500;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.leading-snug {
  line-height: 1.375;
}
.leading-tighter {
  line-height: 1.125;
}
.tracking-tight {
  letter-spacing: -0.01em;
}
.tracking-tighter {
  letter-spacing: -0.02em;
}
.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(0 112 244 / var(--tw-text-opacity));
}
.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(234 234 234 / var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(153 153 153 / var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(76 76 76 / var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
}
.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(25 25 25 / var(--tw-text-opacity));
}
.text-inherit {
  color: inherit;
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
.text-transparent {
  color: transparent;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.underline {
  text-decoration-line: underline;
}
.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 1;
}
.opacity-95 {
  opacity: 0.95;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.15);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.04), 0 4px 6px -2px rgba(0, 0, 0, 0.02);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.04), 0 2px 4px -1px rgba(0, 0, 0, 0.03);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.12), 0 10px 10px -5px rgba(0, 0, 0, 0.02);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 10px 10px -5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.duration-100 {
  transition-duration: 100ms;
}
.duration-150 {
  transition-duration: 150ms;
}
.duration-200 {
  transition-duration: 200ms;
}
.duration-300 {
  transition-duration: 300ms;
}
.duration-700 {
  transition-duration: 700ms;
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
#app-main.loading {
  opacity: 0.25;
  transition: opacity 200ms;
  transition-delay: 200ms;
}
#app-profile.loading {
  opacity: 0.5;
  transition: opacity 200ms;
  transition-delay: 200ms;
}
.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 100 218 / var(--tw-bg-opacity));
}
.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 51 51 / var(--tw-bg-opacity));
}
.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgb(0 89 194 / var(--tw-text-opacity));
}
.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(25 25 25 / var(--tw-text-opacity));
}
.hover\:underline:hover {
  text-decoration-line: underline;
}
.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.04), 0 4px 6px -2px rgba(0, 0, 0, 0.02);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.group:hover .group-hover\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(0 112 244 / var(--tw-text-opacity));
}
@supports (overflow:clip) {
  .supports-\[overflow\:clip\]\:overflow-clip {
    overflow: clip;
  }
}
@media (prefers-color-scheme: dark) {
  .dark\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgb(51 140 245 / var(--tw-text-opacity));
  }
}
@media (min-width: 640px) {
  .sm\:col-span-12 {
    grid-column: span 12 / span 12;
  }
  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .sm\:mb-0 {
    margin-bottom: 0px;
  }
  .sm\:ml-4 {
    margin-left: 1rem;
  }
  .sm\:flex {
    display: flex;
  }
  .sm\:h-2\/3 {
    height: 66.666667%;
  }
  .sm\:h-\[462px\] {
    height: 462px;
  }
  .sm\:w-\[500px\] {
    width: 500px;
  }
  .sm\:w-\[750px\] {
    width: 750px;
  }
  .sm\:w-auto {
    width: auto;
  }
  .sm\:max-w-none {
    max-width: none;
  }
  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .sm\:justify-center {
    justify-content: center;
  }
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (min-width: 768px) {
  .md\:order-1 {
    order: 1;
  }
  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .md\:mb-0 {
    margin-bottom: 0px;
  }
  .md\:mt-24 {
    margin-top: 6rem;
  }
  .md\:mt-6 {
    margin-top: 1.5rem;
  }
  .md\:flex {
    display: flex;
  }
  .md\:grid {
    display: grid;
  }
  .md\:hidden {
    display: none;
  }
  .md\:h-20 {
    height: 5rem;
  }
  .md\:w-full {
    width: 100%;
  }
  .md\:max-w-2xl {
    max-width: 42rem;
  }
  .md\:max-w-none {
    max-width: none;
  }
  .md\:grow {
    flex-grow: 1;
  }
  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:gap-6 {
    gap: 1.5rem;
  }
  .md\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .md\:pb-0 {
    padding-bottom: 0px;
  }
  .md\:pb-16 {
    padding-bottom: 4rem;
  }
  .md\:pb-8 {
    padding-bottom: 2rem;
  }
  .md\:pt-20 {
    padding-top: 5rem;
  }
  .md\:pt-40 {
    padding-top: 10rem;
  }
  .md\:text-6xl {
    font-size: 5.5rem;
  }
}
@media (min-width: 1024px) {
  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .lg\:mt-0 {
    margin-top: 0px;
  }
  .lg\:max-w-none {
    max-width: none;
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:text-right {
    text-align: right;
  }
}
